import { t } from "@lingui/macro";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useUser } from "@/client/services/user";

import CardScrolingTem from "../card-scroling-tem";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

type Job = {
  id: string;
  title: string;
  salary: number;
};

type Placement = {
  title: string;
  jobs: Job[];
  start_date: string;
  end_date: string;
  company?: { name: string };
  type: string;
};

type Notification = {
  title: string;
  description: string;
  status: string;
  created_at: string;
};

function DashboardReportPage() {
  const [data, setData] = useState<Placement[]>([]);
  const [notification, setNotification] = useState<Notification[]>([]);
  const [expandedNotification, setExpandedNotification] = useState<Set<number>>(new Set());
  console.log("notify", notification);
  const { user } = useUser();
  const userId = user?.studentId;
  useEffect(() => {
    const fetchData = () => {
      const timer = setTimeout(async () => {
        try {
          const res = await axios.get(`/api/placement/${userId}`);
          setData(res.data.result);
          const resNotification = await axios.get(`/api/notification/${userId}`);
          setNotification(resNotification.data.result);
        } catch (error) {
          console.error(error);
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    };

    fetchData();
  }, []);

  // Flattening the structure to map the jobs for each placement
  const rows = data.flatMap((item: Placement) => {
    return item.jobs.map((job: Job) => ({
      id: job.id,
      title: item.title,
      jobTitle: job.title,
      startDate: item.start_date,
      endDate: item.end_date,
      company: item.company?.name,
      type: item.type,
      salary: job.salary,
    }));
  });
  const toggleDescription = (index: number) => {
    setExpandedNotification((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(index)) {
        newExpanded.delete(index);
      } else {
        newExpanded.add(index);
      }
      return newExpanded;
    });
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
      <Grid container spacing={2}>
        <Grid size={8}>
          <Typography
            sx={{
              textAlign: "left",
              marginLeft: "20px",
              color: "primary.main",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {t`Placements`}
          </Typography>
        </Grid>
        <Grid size={4}>
          <Typography
            sx={{
              textAlign: "left",
              marginLeft: "20px",
              color: "primary.main",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {t`Notifications`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "10px", padding: "5px" }}>
        <Grid size={8}>
          <CardScrolingTem>
            {rows.map((row, index) => (
              <Item
                sx={{
                  marginBottom: "15px",
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  boxShadow: "0px 0px 2px 2px #d1ddeb",
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    // eslint-disable-next-line lingui/no-unlocalized-strings

                    color: "primary.main",
                    fontWeight: "bold",
                  }}
                >
                  {row.title}
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    // eslint-disable-next-line lingui/no-unlocalized-strings
                    gridTemplateColumns: "repeat(3, 1fr)",
                    fontWeight: "bold",
                    color: "primary.main",
                    marginTop: "10px",
                  }}
                >
                  <p>{t`Job Title`}</p>
                  <p>{t`Start Date`}</p>
                  <p>{t`End Date`}</p>
                </Box>
                <div
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    fontWeight: "bold",
                  }}
                >
                  <p>{row.jobTitle}</p>
                  <p>{new Date(row.startDate).toLocaleDateString()}</p>
                  <p>{new Date(row.endDate).toLocaleDateString()}</p>
                </div>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    fontWeight: "bold",
                    color: "primary.main",
                    marginTop: "10px",
                  }}
                >
                  <p>Company Name</p>
                  <p>Type</p>
                  <p>Salary</p>
                </Box>
                <div
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    fontWeight: "bold",
                  }}
                >
                  <p>{row.company}</p>
                  <p>{row.type}</p>
                  <p>{row.salary}</p>
                </div>
              </Item>
            ))}
            {rows.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 15 }}>
                <img src="/placements.png" width={470} height={470} alt="Notifications" />
              </Box>
            )}
          </CardScrolingTem>
        </Grid>
        <Grid size={4}>
          <CardScrolingTem>
            {notification.map((row, index) => (
              <Item
                sx={{ marginBottom: "15px", boxShadow: "0px 0px 2px 2px #d1ddeb", p: 1 }}
                key={index}
              >
                <Typography sx={{ color: "primary.main", fontWeight: "bold", fontSize: "15px" }}>
                  {row.title}
                </Typography>
                <Box
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitLineClamp: expandedNotification.has(index) ? "none" : 2,
                    WebkitBoxOrient: "vertical",
                    marginTop: "5px",
                  }}
                >
                  <Typography sx={{ color: "gray", fontSize: "13px" }}>
                    <NotificationsActiveOutlinedIcon
                      sx={{ fontSize: "17px", color: "gray", mr: "4px" }}
                    />
                    {row.description}
                  </Typography>
                </Box>

                <IconButton
                  onClick={() => toggleDescription(index)}
                  sx={{ marginTop: "3px", p: 0, m: 0 }}
                >
                  {expandedNotification.has(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Item>
            ))}
            {notification.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 17 }}>
                <img src="/notification2.png" width={230} height={230} alt="Placements" />
              </Box>
            )}
          </CardScrolingTem>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardReportPage;
