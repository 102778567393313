// // import { Roboto } from 'next/font/google';
// import { createTheme } from "@mui/material/styles";
// import type {} from "@mui/x-data-grid/themeAugmentation";
// // const roboto = Roboto({
// //   weight: ['300', '400', '500', '700'],
// //   subsets: ['latin'],
// //   display: 'swap',
// // });

// const theme = createTheme({
//   // typography: {
//   //   fontFamily: roboto.style.fontFamily,
//   // },
//   mixins: {
//     MuiDataGrid: {
//       // Pinned columns sections
//       pinnedBackground: "#102d4d",
//       // Headers, and top & bottom fixed rows
//       containerBackground: "#102d4d",
//     },
//   },
//   palette: {
//     mode: "light",
//     primary: {
//       main: "#102D4D",
//       light: "#184872",
//       dark: "#102d4d",
//     },
//     success: {
//       main: "#15a81a",
//       light: "#24b724",
//       dark: "#009608",
//     },
//   },
// });

// export default theme;
"use client";
import type {} from "@mui/x-data-grid/themeAugmentation";
// import { Roboto } from 'next/font/google';
import { createTheme } from "@mui/material/styles";

// Extend the PaletteOptions interface to add the custom color
declare module "@mui/material/styles" {
  interface Palette {
    // ochre: Palette['primary'];
    green: Palette["primary"];
    blue: Palette["primary"];
    red: Palette["primary"];
  }
  interface PaletteOptions {
    // ochre?: PaletteOptions['primary'];
    green?: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    red: PaletteOptions["primary"];
  }
}

// const roboto = Roboto({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
// });

const theme = createTheme({
  // typography: {
  //   fontFamily: roboto.style.fontFamily,
  // },
  mixins: {
    MuiDataGrid: {
      // Pinned columns sections
      pinnedBackground: "#102d4d",
      // Headers, and top & bottom fixed rows
      containerBackground: "#102d4d",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#102D4D",
      light: "#203592",
      dark: "#102D4D",
    },
    success: {
      main: "#15a81a",
      light: "#24b724",
      dark: "#009608",
    },
    // ochre: {
    //   main: '#E3D026',
    //   light: '#E9DB5D',
    //   dark: '#A29415',
    //   contrastText: '#242105',
    // },
    green: {
      main: "rgb(17, 141, 87)",
      light: "rgba(34, 197, 94, 0.16)",
      dark: "#009608",
    },
    // orange: {
    //   main: 'rgb(183, 110, 0)',
    //   light: 'rgba(255, 143, 0, 0.16)',
    // },
    red: {
      main: "rgb(183, 29, 24)",
      light: "rgba(255, 86, 48, 0.16)",
    },
    blue: {
      main: "rgb(0, 77, 183)",
      light: "rgba(0, 77, 183, 0.16)",
    },
  },
});

export default theme;
