import { Box } from "@mui/material";
import React from "react";

type CardScrolingTemProps = {
  children: React.ReactNode;
};

const CardScrolingTem: React.FC<CardScrolingTemProps> = ({ children }) => {
  return (
    <Box
      sx={{
        paddingTop: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 1,
        backgroundColor: "#fff",
        // border: '1px solid #ccc',
        // borderRadius: 2,
        height: "80vh",
        overflowX: "auto",
        // Custom scrollbar styling
        "&::-webkit-scrollbar": {
          width: "8px", // Width of the scrollbar
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#dbdad7", // Color of the scrollbar thumb
          borderRadius: "10px", // Rounded corners for the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Darker color when hovering over the scrollbar thumb
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
          borderRadius: "10px", // Rounded corners for the scrollbar track
        },
      }}
    >
      {children}
    </Box>
  );
};

export default CardScrolingTem;
